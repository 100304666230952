import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x0bbC860BBf51E8D67EA11db1008ab896Fcd7B9A2',
  [ChainId.TESTNET]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x7a69E773a46C74F9206c733B2E7DeDA6EfB933e9',
  [ChainId.BSC]: '0xDef8551d5b6Fb496C8D4220bF17E8aCD1abaF42F'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
