import React from 'react'
import { Price } from '@uniswap/sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import { useActiveWeb3React } from '../../hooks'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)
  
  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${(chainId === 42 && price?.quoteCurrency?.symbol=='HT')? 'ETH' : (chainId === 56 && price?.quoteCurrency?.symbol=='HT')? 'BNB' :price?.quoteCurrency?.symbol} per ${(chainId === 42 && price?.baseCurrency?.symbol=='HT')? 'ETH' : (chainId === 56 && price?.baseCurrency?.symbol=='HT')? 'BNB' :price?.baseCurrency?.symbol}`
    : `${(chainId === 42 && price?.baseCurrency?.symbol=='HT')? 'ETH' : (chainId === 56 && price?.baseCurrency?.symbol=='HT')? 'BNB' :price?.baseCurrency?.symbol} per ${(chainId === 42 && price?.quoteCurrency?.symbol=='HT')? 'ETH' : (chainId === 56 && price?.quoteCurrency?.symbol=='HT')? 'BNB' :price?.quoteCurrency?.symbol}`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
