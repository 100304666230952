import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'


export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const { t } = useTranslation()
  const { account, chainId, library } = useActiveWeb3React()
  const symbola = (chainId === 42 && currencies[Field.CURRENCY_A]?.symbol=='HT')? 'ETH' : (chainId === 56 && currencies[Field.CURRENCY_A]?.symbol=='HT')? 'BNB' : currencies[Field.CURRENCY_A]?.symbol
  const symbolb = (chainId === 42 && currencies[Field.CURRENCY_B]?.symbol=='HT')? 'ETH' : (chainId === 56 && currencies[Field.CURRENCY_B]?.symbol=='HT')? 'BNB' : currencies[Field.CURRENCY_B]?.symbol

  return (
    <>
      <RowBetween>
        <TYPE.body>{symbola} {t("Deposited")}</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{symbolb} {t("Deposited")}</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{t("Rates")}</TYPE.body>
        <TYPE.body>
          {`1 ${symbola} = ${price?.toSignificant(4)} ${
            symbolb
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.body>
          {`1 ${symbolb} = ${price?.invert().toSignificant(4)} ${
            symbola
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{t("Share of Pool")}:</TYPE.body>
        <TYPE.body>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TYPE.body>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </Text>
      </ButtonPrimary>
    </>
  )
}
